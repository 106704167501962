import React from "react"
import cx from "classnames"
import parse from "html-react-parser"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import { LinkToForm } from "@components/common/LinkToForm"
import TrianglesFirst from "@icons/ProductsSingleDesign1.svg"
import TrianglesSecond from "@icons/ProductsSingleDesign2.svg"
import TrianglesThird from "@icons/ProductsSingleDesign3.svg"

import * as s from "./ProductsContent.module.sass"

type ProductsContentProps = {
  className?: string
  title: string
  content: string
}

export const ProductsContent: React.FC<ProductsContentProps> = ({
  title,
  content,
  className,
}) => {
  return (
    <section className={cx(s.root, className)}>
      <TrianglesFirst className={s.triangleFirst} />
      <TrianglesSecond className={s.triangleSecond} />
      <TrianglesThird className={s.triangleThird} />
      <Container>
        <Row className={s.row}>
          <LinkToForm className={s.link} />
          <h1 className={s.header}>{title}</h1>
          <div className={s.content}>{parse(content)}</div>
        </Row>
      </Container>
    </section>
  )
}
