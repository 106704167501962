import React from "react"
import { graphql } from "gatsby"

import { CustomPageProps } from "@utils/types"
import { BaseLayout } from "@layouts/BaseLayout"
import { ProductsContent } from "@components/products/ProductsContent"

const ProductsSinglePage: React.FC<CustomPageProps> = ({
  data,
  pageContext,
  location,
}) => {
  // @ts-ignore
  const content = data.directus.items.products[0].translations[0]

  return (
    <BaseLayout
      theme="gray"
      title={content.title}
      description={content.description}
      pageContext={{
        slug: "/",
        langKey: pageContext.lang === "uk-UA" ? "ua" : "ru",
      }}
      location={location}
    >
      <ProductsContent title={content.title} content={content.content} />
    </BaseLayout>
  )
}

export const query = graphql`
  query($slug: String!, $lang: ID!) {
    directus {
      items {
        products(filter: { slug: { _eq: $slug } }) {
          slug
          translations(filter: { languages_code: { code: { _eq: $lang } } }) {
            title
            description
            content
          }
        }
      }
    }
  }
`

export default ProductsSinglePage
